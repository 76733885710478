<!--
 * @Description: 分部分项树
 * @Author: luocheng
 * @Date: 2021-11-24 16:46:11
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2023-11-07 17:25:00
-->
<template>
	<div class="subitem-tree">
    <div class="header">
      <div class="title">{{title}}</div>
      <div v-if="isAdd" class="icon" @click="handleAdd" ><i class="el-icon-plus"></i></div>
    </div>
		<el-input
			v-model="filterText"
			placeholder="请输入关键字搜索"
			clearable
      type="text"
			v-if="withFilter"
      prefix-icon="el-icon-search"
		>
		</el-input>
		<el-tree
			ref="subitemTree"
			:data="treeData"
			node-key="id"
			highlight-current
			:props="treeProps"
			:expand-on-click-node="false"
			:check-on-click-node="true"
			:check-strictly="type === 'default'"
			:show-checkbox="showCheckBox"
			:default-checked-keys="defaultChecked"
			@check-change="getChecked"
			:class="customClass"
			:filter-node-method="filterNode"
			:default-expand-all="defaultExpandAll"
			:default-expanded-keys="defaultExpandedKeys || []"
		>
			<span class="custom-tree-node"  slot-scope="{ node, data }" >
				<div @click="onCheck(data)" style="width: 100%">
           <!-- 分部分项图标 -->
					<img
						v-if="isSubitem && data.icon !== 'classification'"
						:src="getIcon(data)"
						alt="icon"
						class="node-icon"
					/>
					<span class="label">{{ node.label }}</span>
				</div>
				<span v-if="isStatistics" class="number">{{data.num}}</span>
				<span class="number edit-more">
          <el-dropdown @command="handleCommand($event,data)">
            <span class="el-dropdown-link">
              <i class="el-icon-more"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="add" v-if="data.type === 1">新增目录</el-dropdown-item>
              <el-dropdown-item command="addSpace" v-if="data.type === 1">新增空间</el-dropdown-item>
              <el-dropdown-item command="edit" v-if="data.type === 1 && data.id>0">修改名称</el-dropdown-item>
              <el-dropdown-item command="editSpace" v-if="data.type === 2">修改空间</el-dropdown-item>
              <el-dropdown-item command="del" v-if="data.id>0">删除{{ data.type === 1 ? '目录' : '空间' }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </span>
			</span>
		</el-tree>
	</div>
</template>

<script>
import { Tree,Dropdown,DropdownMenu,DropdownItem } from 'element-ui';

export default {
	name: 'SubitemTree',
	components: {
		'el-tree': Tree,
		'el-dropdown': Dropdown,
		'el-dropdown-menu': DropdownMenu,
		'el-dropdown-item': DropdownItem,
	},
	props: {
    title:{
      type:String,
      required: true,
      default: ''
    },
		// 选中的数据
		value: {
			type: [Array, Number, String],
			default: () => [],
			required: false
		},
		// 树结构数据
		treeData: {
			type: Array,
			required: true,
			default: () => []
		},
		// 树配置
		treeProps: {
			type: Object,
			required: false,
			default: () => {
				return {
					children: 'children',
					label: 'label'
				};
			}
		},
		// 树类型 default 默认 作为选项或菜单  select  配置时候  eyes 眼睛特殊样式 leafOnly 仅选择叶子节点
		type: {
			type: String,
			required: false,
			default: 'default'
		},
		// 是否默认选中一个
		defaultSelect: {
			type: Boolean,
			default: false,
			required: false
		},
		// 是否可以筛选
		withFilter: {
			type: Boolean,
			required: false,
			default: false
		},
		// 不可选规则
		disabledRule: {
			type: [String, Boolean],
			required: false,
			default: false
		},
		// 是否为分部分项树
		isSubitem: {
			type: Boolean,
			required: false,
			default: true
		},
		// 默认展开所有
		defaultExpandAll: {
			type: Boolean,
			default: true
		},
		// 是否需要添加按钮
    isAdd:{
			type: Boolean,
			default: true
		},
		// 是否需要统计
		isStatistics:{
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			// 类型
			TYPES: [
				{
					code: 1,
					label: '行业类型',
					value: 'industry_type',
					icon: require('@/assets/images/subitem/file-close.png')
				},
				{
					code: 2,
					label: '版本库',
					value: 'version',
					icon: require('@/assets/images/subitem/bbk.png')
				},
				{
					code: 3,
					label: '项目类型',
					value: 'project_type',
					icon: require('@/assets/images/subitem/xmlx.png')
				},
				{
					code: 4,
					label: '单位工程',
					value: 'unit_engineering',
					icon: require('@/assets/images/subitem/dwgc.png')
				},
				{
					code: 5,
					label: '子单位工程',
					value: 'subunit_engineering',
					icon: require('@/assets/images/subitem/zdwgc.png')
				},
				{
					code: 6,
					label: '分部工程',
					value: 'division_of_engineering',
					icon: require('@/assets/images/subitem/fbgc.png')
				},
				{
					code: 7,
					label: '子分部工程',
					value: 'subdivision_project',
					icon: require('@/assets/images/subitem/zdwgc.png')
				},
				{
					code: 8,
					label: '分项工程',
					value: 'sectional_works',
					icon: require('@/assets/images/subitem/fxgc.png')
				},
				{
					code: 9,
					label: '子分项工程',
					value: 'sub_projects',
					icon: require('@/assets/images/subitem/zfxgc.png')
				},
				{
					code: 10,
					label: '检验批',
					value: 'inspection_lot',
					icon: require('@/assets/images/subitem/jyp.png')
				},
				{
					code: 11,
					label: '仅分类',
					value: 'classification',
					icon: ''
				},
				{
					code: 12,
					label: '表格',
					value: 'table',
					icon: require('@/assets/images/subitem/table.png')
				},
				{
					code: 13,
					label: '文本',
					value: 'text',
					icon: require('@/assets/images/subitem/text.png')
				},
				{
					code: 14,
					label: '检查',
					value: 'xmlx',
					icon: require('@/assets/images/subitem/xmlx.png')
				}
			],
			// 默认选中
			defaultChecked: [],
			// 选中的节点
			checkKeys: [],
			// 筛选文本
			filterText: ''
		};
	},
	created() {
		this.defaultChecked = this.value || [];
	},
	computed: {
		// 显示复选框
		showCheckBox() {
			return this.type !== 'default' && this.type !== 'leafOnly';
		},
		// 自定义类名
		customClass() {
			return `${this.type}-custom-tree`;
		},
		// 默认展开数据
		defaultExpandedKeys() {
			if (!this.value) return [];
			return Array.isArray(this.value) ? this.value : [this.value]
		}
	},
	watch: {
		// 过滤
		filterText(val) {
			this.$refs.subitemTree.filter(val);
		}
	},
	mounted() {
		this.setEyes();
		this.$nextTick(() => {
      if (this.value && this.value.length) {
        this.$refs.subitemTree && this.$refs.subitemTree.setCurrentKey(this.value[0]);
      }
		});
	},
	methods: {
		handleAdd(){
			this.$emit('add');
		},
    handleCommand(event,row){
      this.$emit('on-trigger',event,row)
    },
		/**
		 * @desc: 点击内容选中（仅选中当前点击的节点其他节点取消）
		 * @param {*} data
		 * @param {*} node
		 * @return {*}
		 */
		onCheck(data, node) {
			console.log(data, node, '点击节点');
			if (this.type === 'default') {
				this.$emit('setNodes', data);
			}
			if (this.type !== 'select') {
				this.$refs.subitemTree && this.$refs.subitemTree.setCheckedNodes([node]);
			}
			this.getChecked();
		},
		/**
		 * @desc: 获取当前选中的key以及节点对象
		 */
		getChecked() {
			this.$nextTick(() => {
				let keys = [];
				const treeEl = this.$refs.subitemTree;
				if (this.type === 'select') {
					// 需要包含半选节点
					const nodes = treeEl.getCheckedNodes(false, true);
					keys = nodes.map((ele) => ele.id);
				} else if (this.type === 'leafOnly') {
					keys = treeEl.getCheckedKeys(true, true);
				} else {
					keys = treeEl.getCheckedKeys(false, true);
				}
				this.checkKeys = keys;
				// console.log(this.checkKeys, 'state.checkKeysstate.checkKeys');
				this.$emit('input', this.type === 'default' ? keys && keys[0] : keys);
			});
		},
		/**
		 * @desc: 节点过滤
		 * @param {String} 关键字
		 * @return {Object} 行数据
		 */
		filterNode(value, data) {
			if (!value) return true;
			return data[this.treeProps.label].toString().indexOf(value) > -1;
		},
		/**
		 * @desc: 设置眼睛节点
		 */
		setEyes() {
			this.$nextTick(() => {
				if (this.type === 'eyes') {
					const boxs = document.getElementsByClassName('el-checkbox');
					if (boxs && boxs.length) {
						for (let i = 0; i < boxs.length; i++) {
							const iconBox = document.createElement('i'); //新建一个div标签
							iconBox.classList.add('iconfont');
							iconBox.classList.add('icon005yanjing-3');
							boxs[i].appendChild(iconBox);
							setTimeout(() => {
								const iconBox1 = document.createElement('i'); //新建一个div标签
								iconBox1.classList.add('iconfont');
								iconBox1.classList.add('icon005yanjing-2');
								boxs[i].appendChild(iconBox1);
							}, 0);
						}
					}
				}
			});
		},
		/**
		 * @desc: 获取对应节点ICON
		 * @param {*}
		 * @return {*}
		 */
		getIcon(data) {
			return this.TYPES.find((ele) => ele.value === data.icon).icon;
		}
	}
};
</script>

<style lang="less">
.subitem-tree {
	height: 100%;
	// width: auto;
	// min-width: 240px;
	width: 100%;
	border: 1px solid #f2f3f5;
	box-sizing: border-box;
	padding: 10px;
	border-radius: 6px;
	overflow: hidden;
	display: flex;
	flex-direction: column;
  .header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title{
      font-size: 14px;
      font-family: var(--systemFontFamily);
      font-weight: 500;
      color: #2A2F3D;
      line-height: 22px;
    }
		.icon{
			cursor: pointer;
		}
  }
	.el-input {
    margin-top: 14px;
	}
	.el-tree {
		box-sizing: border-box;
		padding-top: 10px;
		flex: 1;
		width: 100%;
		overflow: hidden;
		overflow-y: auto;
		.el-tree-node__expand-icon {
			font-size: 16px;
		}
		&.eyes-custom-tree {
			.el-tree-node__content {
				position: relative;
				.el-checkbox {
					position: absolute;
					box-sizing: border-box;
					right: 0;
					z-index: 1;
					width: 30px;
					padding: 0 8px;
					.iconfont {
						position: absolute;
						left: 15px;
						font-size: 16px;
						font-weight: bold;
						&.icon005yanjing-3 {
							opacity: 0;
							color: var(--el-color-primary);
						}
						&.icon005yanjing-2 {
							opacity: 1;
							color: #c0c4cc;
						}
					}
					&.is-checked {
						.icon005yanjing-3 {
							opacity: 1;
						}
						.icon005yanjing-2 {
							opacity: 0;
						}
					}
					.el-checkbox__input {
						position: absolute;
						right: 0;
						margin: 0;
						opacity: 0;
					}
				}
			}
		}
	}
  .custom-tree-node:hover{
    .edit-more{
      display:block;
    }
  }
	.custom-tree-node {
		height: 30px;
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 14px;
		box-sizing: border-box;
		padding-left: 0;
    .edit-more{
      display:none;
    }
		.number{
			padding-right: 5px;
			height: 22px;
			font-size: 14px;
			font-family: var(--systemFontFamily);
			font-weight: 500;
			color: var(--themeThreeColor);
			line-height: 22px;
		}
		.node-icon {
			height: 18px;
			width: 18px;
			margin-right: 5px;
      vertical-align:text-bottom;
		}
		.label {
			height: 100%;
			line-height: 30px;
			flex: 1;
			font-size: 14px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}
}
</style>
